.app-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    // background:#fff url('../src/assets/images/loading.gif') no-repeat center;
    background-size: 200px auto;
    z-index: 100;
  }

