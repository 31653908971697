/* You can add global styles to this file, and also import other style files */
@import "_fonts/global-fonts.scss";
// @import '~@swimlane/ngx-datatable/index.css';
// @import '~@swimlane/ngx-datatable/themes/material.scss';
// @import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bs-stepper/dist/css/bs-stepper.min.css';

.reset--gutter {
  margin-left: 0;
  margin-right: 0;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.invalid-feedback {
  font-size: 100%;
  display: inline-grid;
}

.mandatory {
  color: #ff0000;
}

.text-danger {
  font-size: 14px;
}

.p-0 {
  padding: 0 !important;
}

.mat-form-field {
  margin-bottom: -20px !important;
}

.custom-p-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.link-pointer {
  cursor: pointer;
}

/*Common Css used everywhere*/
.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.display-inline-block {
  display: inline-block;
}

.close-dialog {
  right: 50px;
  top: -10px;
  position: absolute;
  background: #FFF;
  font-size: 15px;
  border: 1px solid #707070;
  border-radius: 50%;
  padding: 0px 6px 1px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bg-gray {
  background-color: #f2f2f2;
}

.text-heading {
  color: #00435F;
}

.text-blue {
  color: #017dd6 !important;
}


/* --------------------------------------------
* --headers
* -------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  /* color: $color-text-heading; */
  margin-top: 0;
  font-weight: 600;
}

h1,
.h1 {
  font-size: 3.5rem;
  line-height: 3.875rem;
}

h2,
.h2 {
  font-size: 2.125rem;
  line-height: 2.5rem;
}

@media (min-width: 768px) {

  h2,
  .h2 {
    font-size: 2.875rem;
    line-height: 3.25rem;
  }
}

h3,
.h3 {
  font-size: 1.375rem;
  line-height: 1.75rem;
}

@media (min-width: 768px) {

  h3,
  .h3 {
    font-size: 2.25rem;
    line-height: 2.625rem;
  }
}

h4,
.h4 {
  font-size: 1.625rem;
  line-height: 2rem;
}

h5,
.h5 {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 400;
}

h6,
.h6 {
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 400;
}

b,
strong {
  font-weight: 500;
}

/*Buttons in Real Insurance*/
.btn-selected {
  background: #f25618 !important;
  color: #fff;
  cursor: pointer;
  border-radius: 0px;
  font-size: 16px !important;
  padding: 10px 25px !important;
  outline: 0;

  &:hover {
    background: #424698 !important;
  }
}

.btn-real {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-default {
  display: inline-block;
  padding: 11px 25px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 0rem;
  text-transform: capitalize;
}

.items-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pl-5 {
  padding-left: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.btn--loading {
  padding-right: 42px !important;
  transition: all 0.3s;
}

.btn--loading:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 5px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #686d77;
  animation: fa-spin 0.8s linear infinite;
}

.pb-10 {
  padding-bottom: 10px;
}

.mat-button-toggle-checked {
  background-color: #F2561D;
  color: #ffffff;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #F2561D;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #F2561D;
}

.container-real-insurance {
  @media (max-width: 992px) {
    max-width: 820px;
  }
}

.wrap-text {
  white-space: pre-wrap !important;
}

.modal-dialog {
  @media (min-width: 576px) {
    max-width: 720px;
  }
}

.text-bold {
  font-weight: 600 !important;
  font-size: 1rem !important;
}

.btn-login {
  border: none;
  width: 100%;
  height: 51px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #F2561D;
  font-size: 1rem;
  color: #fff;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
}

.posborder {
  border: 1px solid #707070;
  border-radius: 10px;
  padding: 10px !important;
  background-color: #ffffff;
}

.details-container {
  .btn-link {
    text-decoration: none;
    padding: 0;
    width: 100%;
    text-align: left;

    .fa-angle-down {
      color: #000;
      font-size: 26px;
      transform: rotate(180deg);
      transition: all 1s;
    }

    &.collapsed {
      .fa-angle-down {
        transform: rotate(0deg);
      }
    }
  }

  .card {
    margin-bottom: 12px;
  }
}

#userSelectionModal {
  .mmodal-width {
    width: 700px;
  }

  .modal-body {
    p {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }

  .mmodal-width-small {
    @media(max-width: 570px) {
      width: 90%;
    }
  }

  .mmodal-body {
    @media(max-width: 570px) {
      margin: 0;
    }
  }
}

$table-border-color: #dee2e6;

.ngx-datatable {
  border: 1px solid $table-border-color;

  .datatable-header {
    .datatable-header-inner {
      .datatable-header-cell {
        background-color: #222d32;
        color: #fff;
        border-right: 1px solid $table-border-color;
        border-bottom: 1px solid $table-border-color;
      }

    }
  }

  .datatable-body {
    .datatable-row-wrapper {
      .datatable-body-row {
        .datatable-row-group {
          .datatable-body-cell {
            border-right: 1px solid $table-border-color;
            border-bottom: 1px solid $table-border-color;

            &:last-child {
              border-right: 0;
            }
          }
        }
      }
    }
  }
}

.show-password-span {
  position: absolute;
  right: 6px;
  top: calc(50% - 8px);
  cursor: pointer;
}

.mat-step-header {
  pointer-events: none !important;
}